import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'errors', 'content', 'form'
  ];

  createSuccess(event) {
    const [data, _status, xhr] = event.detail;

    this.closeEdit();
  }

  showErrors(event) {
    const [_data, _status, xhr] = event.detail;

    this.errorsTarget.innerHTML = JSON.parse(xhr.response).join('; ');
  }

  showEdit() {
    console.log('showEdit clicked');

    this.contentTarget.classList.remove('d-block');
    this.contentTarget.classList.add('d-none');

    this.formTarget.classList.remove('d-none');
    this.formTarget.classList.add('d-block');
  }

  closeEdit() {
    this.contentTarget.classList.remove('d-none');
    this.contentTarget.classList.add('d-block');

    this.formTarget.classList.remove('d-block');
    this.formTarget.classList.add('d-none');

    this.errorsTarget.innerHTML = '';
  }
}
